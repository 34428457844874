<template>
  <div class="breakout-history q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['treatedForAcne'])">
      <single-answer-question
        title="label.treatedForAcne"
        :value="treatedForAcne"
        :options="$options.responseOptions"
        yes-no
        two-columns
        @input="onFieldChange('treatedForAcne', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="isTreatedForAcne"
        class="q-mt32 q-mt40-md"
        :error="getFieldsError(['acneTreatments'])"
      >
        <multi-answer-question
          id="acne-treatments"
          title="label.acneTreatments"
          :value="acneTreatments"
          :grouped-options="acneTreatmentBindings"
          @input="onFieldChange('acneTreatments', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { female } from '@/modules/questionnaire/api/helpers';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

import {
  ACNE_TREATMENT_OVER_THE_COUNTER_MEDICINE,
  ACNE_TREATMENT_TOPICAL_ANTIBIOTICS,
  ACNE_TREATMENT_ORAL_ANTIBIOTICS,
  ACNE_TREATMENT_OTHER_MEDICINE
} from '@/modules/questionnaire/api/constants';

const UNSURE = 'unsure';

const OVER_THE_COUNTER_MEDICINE = [
  {
    value: ACNE_TREATMENT_OVER_THE_COUNTER_MEDICINE.BENZOYL_PEROXIDE,
    text: 'acneTreatment.benzoylPeroxide'
  },
  {
    value: ACNE_TREATMENT_OVER_THE_COUNTER_MEDICINE.SALICYCLIC_ACID,
    text: 'acneTreatment.salicylicAcid'
  },
  { value: ACNE_TREATMENT_OVER_THE_COUNTER_MEDICINE.SULFUR, text: 'acneTreatment.sulfur' },
  {
    value: ACNE_TREATMENT_OVER_THE_COUNTER_MEDICINE.TOPICAL_RETINOL_GEL,
    text: 'acneTreatment.topicalRetinolGel'
  }
];

const TOPICAL_ANTIBIOTICS = [
  { value: ACNE_TREATMENT_TOPICAL_ANTIBIOTICS.CLINDAMYCIN, text: 'acneTreatment.clindamycin' },
  {
    value: ACNE_TREATMENT_TOPICAL_ANTIBIOTICS.TOPICAL_ERYTHROMYCIN,
    text: 'acneTreatment.erythromycin'
  }
];

const ORAL_ANTIBIOTICS = [
  { value: ACNE_TREATMENT_ORAL_ANTIBIOTICS.DOXYCYCLINE, text: 'acneTreatment.doxyccycline' },
  { value: ACNE_TREATMENT_ORAL_ANTIBIOTICS.ORAL_ERYTHROMYCIN, text: 'acneTreatment.erythromycin' },
  { value: ACNE_TREATMENT_ORAL_ANTIBIOTICS.MINOCYCLINE, text: 'acneTreatment.minocycline' },
  { value: ACNE_TREATMENT_ORAL_ANTIBIOTICS.TETRACYCLINE, text: 'acneTreatment.tetracycline' }
];

const OTHER_MEDICINE_FEMALE = [
  {
    value: ACNE_TREATMENT_OTHER_MEDICINE.TOPICAL_RETINOID,
    text: 'acneTreatment.topicalRetinoid'
  },
  { value: ACNE_TREATMENT_OTHER_MEDICINE.ORAL_RETINOID, text: 'acneTreatment.oralRetinoid' },
  {
    value: ACNE_TREATMENT_OTHER_MEDICINE.ORAL_CONTRACEPTIVES,
    text: 'acneTreatment.oralContraceptives'
  },
  { value: UNSURE, text: 'acneTreatment.unsure', overridesAll: true }
];

const OTHER_MEDICINE_MALE = [
  {
    value: ACNE_TREATMENT_OTHER_MEDICINE.TOPICAL_RETINOID,
    text: 'acneTreatment.topicalRetinoid'
  },
  { value: ACNE_TREATMENT_OTHER_MEDICINE.ORAL_RETINOID, text: 'acneTreatment.oralRetinoid' },

  { value: UNSURE, text: 'acneTreatment.unsure', overridesAll: true }
];

export default {
  name: 'BreakoutHistoryTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionWithErrorWrap,
    SingleAnswerQuestion,
    MultiAnswerQuestion
  },
  mixins: [stepTemplateMixin],
  responseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    treatedForAcne: {
      type: String,
      default: ''
    },
    acneTreatments: {
      type: Array,
      default: () => []
    },
    gender: {
      type: String,
      default: ''
    },
    isTreatedForAcne: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    otherAcneTreatmentByGender() {
      return female(this.gender) ? OTHER_MEDICINE_FEMALE : OTHER_MEDICINE_MALE;
    },
    acneTreatmentBindings() {
      return [
        {
          title: this.$t('breakoutHistory.label.overTheCounterProducts'),
          options: OVER_THE_COUNTER_MEDICINE
        },
        {
          title: this.$t('breakoutHistory.label.topicalAntibiotics'),
          options: TOPICAL_ANTIBIOTICS
        },
        { title: this.$t('breakoutHistory.label.oralAntibiotics'), options: ORAL_ANTIBIOTICS },
        {
          title: this.$t('breakoutHistory.label.other'),
          options: this.otherAcneTreatmentByGender,
          overridesAll: true
        }
      ];
    }
  }
};
</script>
